export default {
  "result.fail.error.title": "提交失败",
  "result.fail.error.description": "请核对并修改以下信息后，再重新提交。",
  "result.fail.error.hint-title": "您提交的内容有如下错误：",
  "result.fail.error.hint-text1": "您的账户已被冻结",
  "result.fail.error.hint-btn1": "立即解冻",
  "result.fail.error.hint-text2": "您的账户还不具备申请资格",
  "result.fail.error.hint-btn2": "立即升级",
  "result.fail.error.btn-text": "返回修改",
};

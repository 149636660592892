import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import "@/styles/normalize.css";
import "@/styles/markdown-segmentfault.css"; //markdown样式
import "@/styles/mobile.less";
import i18n from "./locales";
import vRegion from "v-region";

import {
  Carousel,
  Icon,
  Input,
  Button,
  Checkbox,
  Menu,
  Form,
  Popover,
  Progress,
  Modal,
  Col,
  Row,
  Upload,
  message,
  notification,
  Table,
  AutoComplete,
  TreeSelect,
  Select,
} from "ant-design-vue";
Vue.component(Carousel.name, Carousel);
Vue.component(Icon.name, Icon);
Vue.component(Input.name, Input);
Vue.component(Input.Password.name, Input.Password);
Vue.component(Input.Search.name, Input.Search);
Vue.component(Button.name, Button);
Vue.component(Checkbox.name, Checkbox);
Vue.component(Menu.name, Menu);
Vue.component(Menu.Item.name, Menu.Item);
Vue.component(Menu.SubMenu.name, Menu.SubMenu);
Vue.component(Form.name, Form);
Vue.component(Form.Item.name, Form.Item);
Vue.component(Popover.name, Popover);
Vue.component(Progress.name, Progress);
Vue.component(Modal);
Vue.component(Col.name, Col);
Vue.component(Row.name, Row);
Vue.component(Upload.name, Upload);
Vue.component(Table.name, Table);
Vue.component(AutoComplete.name, AutoComplete);
Vue.component(TreeSelect.name, TreeSelect);
Vue.component(Select.name, Select);
Vue.component(Modal.name, Modal);
Vue.prototype.$success = Modal.success;
Vue.prototype.$notification = notification;
Vue.config.ignoredElements = ["wx-open-launch-weapp"];
Vue.prototype.$message = message;
Vue.use(vRegion);

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  computed: {
    ...mapGetters(["token", "userInfo"]),
  },
  created() {
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("token", this.token);
      sessionStorage.setItem("userInfo", JSON.stringify(this.userInfo));
    });
    if (sessionStorage.getItem("userInfo")) {
      this.SET_USER_INFO(JSON.parse(sessionStorage.getItem("userInfo")));
    }
    if (sessionStorage.getItem("token")) {
      this.SET_TOKEN(sessionStorage.getItem("token"));
    }
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("userInfo");
  },
  methods: {
    ...mapMutations(["SET_TOKEN", "SET_USER_INFO"]),
  },
};
</script>

<style lang="less">
#app{
  height: 100%;
}
html {
  font-size: 5.2083333vw;
  margin: 0 auto;
  body {
    font-size: 0.12rem;
    line-height: 1.5;
    color: #000;
    background: #fff;
  }
}
@media screen and (min-width: 1920px) {
  html {
    font-size: 100px;
    max-width: 1920px;
  }
}
@media screen and (min-width: 600px) {
  /* 自定义滚动条 */
  ::-webkit-scrollbar {
    width: 10px;
    height: 15px;
  }

  ::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px; */
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5);
    /* background: skyblue; */
  }
  /* 自定义滚动条 */

  
}
</style>
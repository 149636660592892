import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import Layout from "@/layouts/index.vue";

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "home",
        component: () => import("@/views/home/index"),
        name: "Home",
      },
      {
        path: "home/news",
        component: () => import("@/views/news/index"),
        name: "News",
      },
      {
        path: "home/solution",
        component: () => import("@/views/solution/index"),
        name: "Solution",
      },
      {
        path: "about-us",
        component: () => import("@/views/about-us/index"),
        name: "AboutUs",
      },
      {
        path: "business-standard",
        component: () => import("@/views/business-standard/index"),
        name: "BusinessStandard",
      },
      {
        path: "use-guide",
        component: () => import("@/views/use-guide/index"),
        name: "UseGuide",
      },
      {
        path: "access-guide",
        redirect: "access-guide/index",
      },
      {
        path: "access-guide/index",
        name: "AccessGuide",
        component: () => import("@/views/guide/index"),
      },
      {
        path: "access-guide/codeApply",
        name: "codeApply",
        component: () => import("@/views/guide/api/codeApply/CodeApply"),
      },
      {
        path: "access-guide/codeFallback",
        name: "codeFallback",
        component: () => import("@/views/guide/api/codeFallback/CodeFallback"),
      },
      {
        path: "access-guide/codeReturn",
        name: "codeReturn",
        component: () => import("@/views/guide/api/codeReturn/CodeReturn"),
      },
      {
        path: "access-guide/codeQuery",
        name: "codeQuery",
        component: () => import("@/views/guide/api/codeQuery/CodeQuery"),
      },
      {
        path: "access-guide/codeInfoQuery",
        name: "codeQuery",
        component: () =>
          import("@/views/guide/api/codeInfoQuery/CodeInfoQuery"),
      },
      {
        path: "access-guide/encrypt",
        name: "encrypt",
        component: () => import("@/views/guide/extra/Encrypt"),
      },
      {
        path: "access-guide/update",
        name: "encrypt",
        component: () => import("@/views/guide/extra/Update"),
      },
      {
        path: "access-guide/errorCode",
        name: "errorCode",
        component: () => import("@/views/guide/extra/ErrorCode"),
      },
    ],
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/register/index"),
  },
  {
    path: "/find-password",
    name: "FindPassword",
    component: () => import("@/views/find-password/index"),
  },
  {
    path: "/information-filling",
    name: "InformationFilling",
    component: () => import("@/views/information-filling/index"),
  },
  {
    path: "/production",
    name: "Production",
    component: () => import("@/views/production/index"),
  },
  {
    path: "*",
    component: () => import("@/views/error-page/404"),
    hidden: true,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;

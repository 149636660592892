import Vue from "vue";
import VueI18n from "vue-i18n";

// default lang
import enUS from "./lang/en-US";
import zhCN from "./lang/zh-CN";

Vue.use(VueI18n);

export let defaultLang = "zh-CN";

if (localStorage.getItem("ecode-lang")) {
  defaultLang = localStorage.getItem("ecode-lang");
}
const messages = {
  "zh-CN": {
    ...zhCN,
  },
  "en-US": {
    ...enUS,
  },
};

const i18n = new VueI18n({
  silentTranslationWarn: true,
  locale: defaultLang,
  fallbackLocale: defaultLang,
  messages,
});

export default i18n;

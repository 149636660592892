export default {
  "form.basic-form.basic.title": "基础表单",
  "form.basic-form.basic.description":
    "表单页用于向用户收集或验证信息，基础表单常见于数据项较少的表单场景。",
  "form.basic-form.title.label": "标题",
  "form.basic-form.title.placeholder": "给目标起个名字",
  "form.basic-form.title.required": "请输入标题",
  "form.basic-form.date.label": "起止日期",
  "form.basic-form.placeholder.start": "开始日期",
  "form.basic-form.placeholder.end": "结束日期",
  "form.basic-form.date.required": "请选择起止日期",
  "form.basic-form.goal.label": "目标描述",
  "form.basic-form.goal.placeholder": "请输入你的阶段性工作目标",
  "form.basic-form.goal.required": "请输入目标描述",
  "form.basic-form.standard.label": "衡量标准",
  "form.basic-form.standard.placeholder": "请输入衡量标准",
  "form.basic-form.standard.required": "请输入衡量标准",
  "form.basic-form.client.label": "客户",
  "form.basic-form.client.required": "请描述你服务的客户",
  "form.basic-form.label.tooltip": "目标的服务对象",
  "form.basic-form.client.placeholder":
    "请描述你服务的客户，内部客户直接 @姓名／工号",
  "form.basic-form.invites.label": "邀评人",
  "form.basic-form.invites.placeholder": "请直接 @姓名／工号，最多可邀请 5 人",
  "form.basic-form.weight.label": "权重",
  "form.basic-form.weight.placeholder": "请输入",
  "form.basic-form.public.label": "目标公开",
  "form.basic-form.label.help": "客户、邀评人默认被分享",
  "form.basic-form.radio.public": "公开",
  "form.basic-form.radio.partially-public": "部分公开",
  "form.basic-form.radio.private": "不公开",
  "form.basic-form.publicUsers.placeholder": "公开给",
  "form.basic-form.option.A": "同事一",
  "form.basic-form.option.B": "同事二",
  "form.basic-form.option.C": "同事三",
  "form.basic-form.email.required": "请输入邮箱地址！",
  "form.basic-form.email.wrong-format": "邮箱地址格式错误！",
  "form.basic-form.userName.required": "请输入用户名!",
  "form.basic-form.password.required": "请输入密码！",
  "form.basic-form.password.twice": "两次输入的密码不匹配!",
  "form.basic-form.strength.msg":
    "请至少输入 6 个字符。请不要使用容易被猜到的密码。",
  "form.basic-form.strength.strong": "强度：强",
  "form.basic-form.strength.medium": "强度：中",
  "form.basic-form.strength.short": "强度：太短",
  "form.basic-form.confirm-password.required": "请确认密码！",
  "form.basic-form.phone-number.required": "请输入手机号！",
  "form.basic-form.phone-number.wrong-format": "手机号格式错误！",
  "form.basic-form.verification-code.required": "请输入验证码！",
  "form.basic-form.form.get-captcha": "获取验证码",
  "form.basic-form.captcha.second": "秒",
  "form.basic-form.form.optional": "（选填）",
  "form.basic-form.form.submit": "提交",
  "form.basic-form.form.save": "保存",
  "form.basic-form.email.placeholder": "邮箱",
  "form.basic-form.password.placeholder": "至少6位密码，区分大小写",
  "form.basic-form.confirm-password.placeholder": "确认密码",
  "form.basic-form.phone-number.placeholder": "手机号",
  "form.basic-form.verification-code.placeholder": "验证码",
  "form.basic-form.ecode-search.placeholder": "请输入您所需要查询的ecode编码",
  "form.basic-form.search": "查询",
  "form.important": "请输入必要信息！",
};

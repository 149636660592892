export default {
  "app.setting.pagestyle": "整体风格设置",
  "app.setting.pagestyle.light": "亮色菜单风格",
  "app.setting.pagestyle.dark": "暗色菜单风格",
  "app.setting.pagestyle.realdark": "暗黑模式",
  "app.setting.themecolor": "主题色",
  "app.setting.navigationmode": "导航模式",
  "app.setting.content-width": "内容区域宽度",
  "app.setting.fixedheader": "固定 Header",
  "app.setting.fixedsidebar": "固定侧边栏",
  "app.setting.sidemenu": "侧边菜单布局",
  "app.setting.topmenu": "顶部菜单布局",
  "app.setting.content-width.fixed": "Fixed",
  "app.setting.content-width.fluid": "Fluid",
  "app.setting.othersettings": "其他设置",
  "app.setting.weakmode": "色弱模式",
  "app.setting.copy": "拷贝设置",
  "app.setting.loading": "加载主题中",
  "app.setting.copyinfo": "拷贝设置成功 src/config/defaultSettings.js",
  "app.setting.production.hint":
    "配置栏只在开发环境用于预览，生产环境不会展现，请拷贝后手动修改配置文件",
  "app.setting.themecolor.daybreak": "拂晓蓝",
  "app.setting.themecolor.dust": "薄暮",
  "app.setting.themecolor.volcano": "火山",
  "app.setting.themecolor.sunset": "日暮",
  "app.setting.themecolor.cyan": "明青",
  "app.setting.themecolor.green": "极光绿",
  "app.setting.themecolor.geekblue": "极客蓝",
  "app.setting.themecolor.purple": "酱紫",
};

<template>
  <header>
    <div class="web-name">
        <img src="../assets/images/logo@1x.png" alt="" style="width:13%;height:auto">
        佛山市产(商)品编码服务平台
    </div>
    <div class="nav">
      <router-link
        to="/home"
        :class="
          currentRoutePath.includes('/home') ? 'nav-item active' : 'nav-item'
        "
        >{{ $t("menu.index") }}</router-link
      >
      <router-link
          to="/business-standard"
          :class="
          currentRoutePath.includes('/business-standard')
            ? 'nav-item active'
            : 'nav-item'
        "
      >{{ $t("menu.businessStandard") }}</router-link
      >
      <!-- <router-link
        to="/about-us"
        :class="
          currentRoutePath.includes('/about-us')
            ? 'nav-item active'
            : 'nav-item'
        "
        >{{ $t("menu.aboutUs") }}</router-link
      > -->
      <router-link
        to="/access-guide"
        :class="
          currentRoutePath.includes('/access-guide')
            ? 'nav-item active'
            : 'nav-item'
        "
        >{{ $t("menu.developerGuide") }}</router-link
      >
      <router-link
        to="/use-guide"
        :class="
          currentRoutePath.includes('/use-guide')
            ? 'nav-item active'
            : 'nav-item'
        "
        >{{ $t("menu.useage") }}</router-link
      >
    </div>
    <div class="avatar" v-if="token && token != 'undefined'">
      <img
        src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png"
        alt=""
      />
      <div class="name">{{ userInfo.enterprise.username }}</div>
      <div class="log-out" @click="handleLogout">
        {{ $t("menu.account.logout") }}
      </div>
    </div>
    <!-- <div
      class="lang"
      :style="{
        marginLeft: (token && token != 'undefined' ? 0.35 : 4.18) + 'rem',
      }"
    > -->
    <div
      class="lang"
      :style="{
        marginLeft: (token && token != 'undefined' ? 0.35 : 5.18) + 'rem',
      }"
    >
      {{ lang === "zh-CN" ? "English" : "中文" }}
      <div class="lang-item">
        <div
          @click="handleLangChange('zh-CN')"
          :class="lang === 'zh-CN' ? 'active' : ''"
        >
          中文
        </div>
        <div
          @click="handleLangChange('en-US')"
          :class="lang === 'en-US' ? 'active' : ''"
        >
          English
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import { Modal } from "ant-design-vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Header",
  data: () => {
    return {
      currentRoutePath: sessionStorage.getItem("routePath")
        ? sessionStorage.getItem("routePath")
        : "/home",
      lang: localStorage.getItem("ecode-lang")
        ? localStorage.getItem("ecode-lang")
        : "zh-CN",
    };
  },
  computed: {
    ...mapGetters(["token", "userInfo"]),
  },
  watch: {
    $route(to, from) {
      this.currentRoutePath = to.path;
      sessionStorage.setItem("routePath", to.path);
    },
  },
  methods: {
    ...mapActions(["Logout"]),
    handleLogout(e) {
      Modal.confirm({
        title: this.$t("layouts.usermenu.dialog.title"),
        content: this.$t("layouts.usermenu.dialog.content"),
        onOk: () => {
          this.Logout(this.userInfo.token).then((res) => {
            this.$message.config({
              top: `500px`,
              duration: 2,
              maxCount: 3,
            });
            if (res.code === 200) {
              this.$message.success(res.message);
            } else if (res.code === 500) {
              this.$message.warning(res.message);
            } else {
              this.$message.error(res.message);
            }
          });
        },
        onCancel() {},
      });
    },
    handleLangChange(lang) {
      this.$i18n.locale = lang;
      this.lang = lang;
      localStorage.setItem("ecode-lang", lang);
    },
  },
};
</script>
<style lang="less" scoped>
header {
  position: sticky;
  top: 0;
  display: flex;
  width: 100%;
  height: 64px;
  align-items: center;
  box-shadow: 0px 10px 10px rgba(94, 126, 254, 0.05);
  background: #fff;
  z-index: 9;
  .web-name {
    margin-left: 3rem;
    font-size: 0.2rem;
    font-weight: bold;
    line-height: 4;
  }
  .nav {
    margin-left: 1rem;
    display: flex;
    .nav-item {
      padding-left: 0.34rem;
      padding-right: 0.34rem;
      text-align: center;
      height: 64px;
      line-height: 64px;
      font-size: 0.16rem;
      color: #666;
      text-decoration: none;
      position: relative;
      &:hover {
        color: #5e7efe;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 4px;
          background: #4f80ff;
        }
      }
      &.active {
        color: #5e7efe;
      }
      &.active::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 4px;
        background: #4f80ff;
      }
    }
  }
  .avatar {
    margin-left: 2.6rem;
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
    img {
      width: 36px;
      height: 36px;
    }
    .name {
      margin-left: 14px;
      font-size: 14px;
      color: #05103d;
    }
    &:hover {
      .log-out {
        display: block;
      }
    }
    .log-out {
      display: none;
      position: absolute;
      left: 0;
      bottom: -26px;
      padding-left: 20px;
      width: 100px;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      background: #fff;
      cursor: pointer;
    }
  }
  .lang {
    font-size: 0.14rem;
    color: #a5a5a5;
    cursor: pointer;
    position: relative;
    &:hover {
      .lang-item {
        display: block;
      }
    }
    .lang-item {
      display: none;
      position: absolute;
      left: -34px;
      bottom: -80px;
      width: 100px;
      cursor: pointer;
      > div {
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #fff;
        &:hover {
          background: rgb(230, 247, 255);
        }
        &.active {
          background: rgb(230, 247, 255);
        }
      }
    }
  }
}
</style>

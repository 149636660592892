var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',[_vm._m(0),_c('div',{staticClass:"nav"},[_c('router-link',{class:_vm.currentRoutePath.includes('/home') ? 'nav-item active' : 'nav-item',attrs:{"to":"/home"}},[_vm._v(_vm._s(_vm.$t("menu.index")))]),_c('router-link',{class:_vm.currentRoutePath.includes('/business-standard')
          ? 'nav-item active'
          : 'nav-item',attrs:{"to":"/business-standard"}},[_vm._v(_vm._s(_vm.$t("menu.businessStandard")))]),_c('router-link',{class:_vm.currentRoutePath.includes('/access-guide')
          ? 'nav-item active'
          : 'nav-item',attrs:{"to":"/access-guide"}},[_vm._v(_vm._s(_vm.$t("menu.developerGuide")))]),_c('router-link',{class:_vm.currentRoutePath.includes('/use-guide')
          ? 'nav-item active'
          : 'nav-item',attrs:{"to":"/use-guide"}},[_vm._v(_vm._s(_vm.$t("menu.useage")))])],1),(_vm.token && _vm.token != 'undefined')?_c('div',{staticClass:"avatar"},[_c('img',{attrs:{"src":"https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png","alt":""}}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.userInfo.enterprise.username))]),_c('div',{staticClass:"log-out",on:{"click":_vm.handleLogout}},[_vm._v(" "+_vm._s(_vm.$t("menu.account.logout"))+" ")])]):_vm._e(),_c('div',{staticClass:"lang",style:({
      marginLeft: (_vm.token && _vm.token != 'undefined' ? 0.35 : 5.18) + 'rem',
    })},[_vm._v(" "+_vm._s(_vm.lang === "zh-CN" ? "English" : "中文")+" "),_c('div',{staticClass:"lang-item"},[_c('div',{class:_vm.lang === 'zh-CN' ? 'active' : '',on:{"click":function($event){return _vm.handleLangChange('zh-CN')}}},[_vm._v(" 中文 ")]),_c('div',{class:_vm.lang === 'en-US' ? 'active' : '',on:{"click":function($event){return _vm.handleLangChange('en-US')}}},[_vm._v(" English ")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"web-name"},[_c('img',{staticStyle:{"width":"13%","height":"auto"},attrs:{"src":require("../assets/images/logo@1x.png"),"alt":""}}),_vm._v(" 佛山市产(商)品编码服务平台 ")])}]

export { render, staticRenderFns }
export default {
  "result.success.title": "Submission Success",
  "result.success.description":
    "The submission results page is used to feed back the results of a series of operational tasks. If it is a simple operation, use the Message global prompt feedback. This text area can show a simple supplementary explanation. If there is a similar requirement for displaying “documents”, the following gray area can present more complicated content.",
  "result.success.operate-title": "Project Name",
  "result.success.operate-id": "Project ID",
  "result.success.principal": "Principal",
  "result.success.operate-time": "Effective time",
  "result.success.step1-title": "Create project",
  "result.success.step1-operator": "Qu Lili",
  "result.success.step2-title": "Departmental preliminary review",
  "result.success.step2-operator": "Zhou Maomao",
  "result.success.step2-extra": "Urge",
  "result.success.step3-title": "Financial review",
  "result.success.step4-title": "Finish",
  "result.success.btn-return": "Back List",
  "result.success.btn-project": "View Project",
  "result.success.btn-print": "Print",
};

import storage from "store";
// import { ACCESS_TOKEN } from "@/store/mutation-types";
import { welcome } from "@/utils/util";
import { login, register, loginout } from "@/api/user";
import User from "@/model/user";

const user = {
  state: {
    token: "",
    userInfo: new User(),
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },

    SET_IZ_APPEND: (state, data) => {
      state.userInfo.izAppend = data;
    },

    SET_USER_INFO: (state, data) => {
      state.userInfo = new User(data);
    },

    CLEAR_USER_INFO: (state) => {
      state.token = "";
      state.userInfo = new User();
    },
  },

  actions: {
    // 登录
    Login({ commit }, param) {
      return new Promise((resolve, reject) => {
        console.log(111);
        login(param)
          .then((res) => {
            if (res.code === 200) {
              const data = res.result;
              commit("SET_TOKEN", data.token);
              commit("SET_USER_INFO", data);
            }
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 注册
    Register({ commit }, param) {
      return new Promise((resolve, reject) => {
        register(param)
          .then((res) => {
            console.log(res);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 退出登录
    Logout({ commit }, param) {
      return new Promise((resolve, reject) => {
        loginout(param)
          .then((res) => {
            if (res.code === 200) {
              // commit('CLEAR_USER_INFO');
            }
            commit("CLEAR_USER_INFO");
            console.log("loginout", res);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};

export default user;

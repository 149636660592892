<template>
  <div class="layout">
    <Header />
    <router-view :key="key"></router-view>
    <Footer :bgColor="bgColor" />
  </div>
</template>
<script>
import Header from "./Header";
import Footer from "./Footer";
export default {
  name: "Layout",
  components: {
    Header,
    Footer,
  },
  data() {
    if (window.location.pathname === "/about-us") {
      return { bgColor: "#F6F7F9" };
    }

    return { bgColor: "#fff" };
  },
  computed: {
    key() {
      return this.$route.fullPath;
    },
  },
  watch: {
    $route(to) {
      console.log(to);
      if (to.path === "/about-us") {
        this.bgColor = "#F6F7F9";
      }
    },
  },
};
</script>
<style lang="less" scoped>
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>

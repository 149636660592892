export default {
  "result.fail.error.title": "Submission Failed",
  "result.fail.error.description":
    "Please check and modify the following information before resubmitting.",
  "result.fail.error.hint-title":
    "The content you submitted has the following error:",
  "result.fail.error.hint-text1": "Your account has been frozen",
  "result.fail.error.hint-btn1": "Thaw immediately",
  "result.fail.error.hint-text2": "Your account is not yet eligible to apply",
  "result.fail.error.hint-btn2": "Upgrade immediately",
  "result.fail.error.btn-text": "Return to modify",
};

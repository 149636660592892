import request from "@/utils/request";
import qs from "qs";

const userApi = {
  Login: "/company/info/login",
  Register: "/company/info/register",
  AppendEnterpriseInfo: "/company/info/appendEnterpriseInfo",
  UploadImage: "/company/info/uploadImage",
  GetQueryUrl: "/company/info/getQueryUrl",
  Loginout: "/company/info/loginout",
  InfoById: "/company/info/getEnterpriseInfoById",
};
const contentType = {
  form: "application/x-www-form-urlencoded",
  json: "application/json;charset=UTF-8",
};
/**
 * login 登录
 * parameter: {
 *     checkCode	验证码(暂不需要)
 *     password	密码
 *     username	用户名（账号）
 * }
 * @param parameter
 * @returns {*}
 */
export function login(parameter) {
  return request({
    url: userApi.Login,
    method: "post",
    headers: {
      "Content-Type": contentType.json,
    },
    data: parameter,
  });
}

/**
 * Register 注册
 * parameter: {
 *     password	密码
 *     username	用户名（账号）
 *     email	用户邮箱
 * }
 * @param parameter
 * @returns {*}
 */
export function register(parameter) {
  return request({
    url: userApi.Register,
    method: "post",
    headers: {
      "Content-Type": contentType.json,
    },
    data: parameter,
  });
}

/**
 * AppendEnterpriseInfo 企业信息追加接口
 */
export function appendEnterpriseInfo(parameter, token) {
  return request({
    url: userApi.AppendEnterpriseInfo,
    method: "post",
    headers: {
      "Content-Type": contentType.json,
      "X-Access-Token": token,
    },
    data: parameter,
  });
}

/**
 * GetQueryUrl 获取编码查询网址
 */
export function getQueryUrl() {
  return request({
    url: userApi.GetQueryUrl,
    method: "get",
    headers: {
      "Content-Type": contentType.form,
    },
  });
}

export function loginout(token) {
  return request({
    url: userApi.Loginout,
    method: "post",
    data: qs.stringify({ token: token }),
    headers: {
      "Content-Type": contentType.form,
    },
  });
}

/**
 * 获取企业信息的接口
 */
export function InfoApi(url,parameter) {
  return request({
    url: url,
    method: 'get',
    params: parameter
  })
}
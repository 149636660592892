export default {
  "result.success.title": "提交成功",
  "result.success.description":
    "提交结果页用于反馈一系列操作任务的处理结果， 如果仅是简单操作，使用 Message 全局提示反馈即可。 本文字区域可以展示简单的补充说明，如果有类似展示 “单据”的需求，下面这个灰色区域可以呈现比较复杂的内容。",
  "result.success.operate-title": "项目名称",
  "result.success.operate-id": "项目 ID",
  "result.success.principal": "负责人",
  "result.success.operate-time": "生效时间",
  "result.success.step1-title": "创建项目",
  "result.success.step1-operator": "曲丽丽",
  "result.success.step2-title": "部门初审",
  "result.success.step2-operator": "周毛毛",
  "result.success.step2-extra": "催一下",
  "result.success.step3-title": "财务复核",
  "result.success.step4-title": "完成",
  "result.success.btn-return": "返回列表",
  "result.success.btn-project": "查看项目",
  "result.success.btn-print": "打印",
};

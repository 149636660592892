export default class User {
  //构造函数
  constructor(user = {}) {
    const {
      username,
      password,
      checkCode,
      token,
      izAppend,
      unPassReason,
      enterprise,
    } = user;

    this.username = username;
    this.password = password;
    this.checkCode = checkCode;
    this.izAppend = izAppend;
    this.unPassReason = unPassReason;
    this.token = token;
    this.enterprise = enterprise;
  }
}
